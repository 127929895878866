:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-white: #fff;
  --color-offwhite: #fafafa;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #ffffffb3;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-desaturated-black-background: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-brand: alternate-gothic-no-3-d, sans-serif;
  --font-main: source-sans-pro, helvetica neue, helvetica, arial, sans-serif;
  --font-highlight: roboto-condensed, helvetica neue, helvetica, arial, sans-serif;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: 1330px;
}

.Suspended {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.Error {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-2x);
  font-family: var(--font-highlight);
  flex-direction: row;
  display: flex;
}

.cookie-consent {
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #000;
  background-color: #ffffffd9;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding: 16px;
  transition: transform .3s ease-in .1s;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.cookie-consent--dark {
  color: #fff;
  background-color: #000000d9;
}

.cookie-consent--dark .cookie-consent__container button {
  color: #fff;
  border: 1px solid #fff;
}

.cookie-consent--visible {
  transition-delay: 1s;
  transform: translateY(0);
}

.cookie-consent__container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

.cookie-consent__container svg {
  flex-shrink: 0;
  width: 25px;
  height: 30px;
}

.cookie-consent__container button {
  cursor: pointer;
  background-color: unset;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  max-width: 80px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
}

:root {
  --color-brand-primary: #141923;
  --color-brand-primary-dark: #0c111b;
  --color-brand-primary-modal: #141923fa;
  --color-brand-primary-overlay: #141923b3;
  --color-brand-secondary: #ff6e40;
  --color-brand-link: #0fb5a7;
  --color-offblack: #333;
  --color-black: #1e1e1e;
  --color-white: #fff;
  --color-offwhite: #fafafa;
  --color-desaturated-white-border: #fff3;
  --color-desaturated-white-text: #ffffffb3;
  --color-desaturated-white-background: #fffc;
  --color-desaturated-black-border: #0000001a;
  --color-desaturated-black-text: #0006;
  --color-desaturated-black-background: #0000004d;
  --color-shadow: #00000040;
  --color-inner-shadow: #0000000d;
  --font-brand: alternate-gothic-no-3-d, sans-serif;
  --font-main: source-sans-pro, helvetica neue, helvetica, arial, sans-serif;
  --font-highlight: roboto-condensed, helvetica neue, helvetica, arial, sans-serif;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: var(--row-gap);
  --size-large-mobile-width: 640px;
  --size-tablet-width: 768px;
  --size-desktop-width: 990px;
  --size-content-width: 1330px;
}

* {
  box-sizing: border-box;
  position: relative;
}

html {
  flex-direction: column;
  min-height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

body {
  background: var(--color-white);
  font-family: var(--font-main);
  margin: 0;
  padding: 0;
}

input {
  font-family: var(--font-main);
}

body {
  flex: 1;
  grid-template-rows: auto 1fr auto;
}

.content {
  background-color: #fff;
  grid-column: full;
  grid-template-columns: [full-start content-start] 1fr[content-end full-end];
  display: grid;
}

.content-dark {
  background-color: var(--color-brand-primary);
}

@media (width >= 990px) {
  .content {
    grid-template-columns: [full-start] 1fr[content-start] 990px[content-end] 1fr[full-end];
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  display: inline-block;
}

button {
  cursor: pointer;
  box-shadow: none;
  font-size: inherit;
  border: 0;
  outline: none;
}

a.inherit {
  color: inherit;
  text-decoration: none;
}

:root {
  --gap: 5vw;
  --row-gap: .55rem;
}

@media (width >= 990px) {
  :root {
    --gap: 18px;
  }

  .mobile-only {
    display: none !important;
  }
}

@media (width <= 990px) {
  .desktop-only {
    display: none !important;
  }
}

/*# sourceMappingURL=app.css.map */
