@use 'root';

* {
	position: relative;
	box-sizing: border-box;
}
html {
	display: flex;
	flex-direction: column;
}
html {
	margin: 0;
	padding: 0;
	min-height: 100%;
}
body {
	margin: 0;
	background: var(--color-white);
	padding: 0;
	font-family: var(--font-main);
}
input {
	font-family: var(--font-main);
}
body {
	grid-template-rows: auto 1fr auto;
	flex: 1;
	/* background-color: ${styles.BRAND_PRIMARY}; */
}
.content {
	display: grid;
	grid-template-columns: [full-start content-start] 1fr [content-end full-end];
	grid-column: full;
	background-color: white;
}
.content-dark {
	background-color: var(--color-brand-primary);
}
@media (min-width: 990px) {
	.content {
		grid-template-columns: [full-start] 1fr [content-start] 990px [content-end] 1fr [full-end];
	}
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: normal;
}
img {
	display: inline-block;
}
button {
	cursor: pointer;
	outline: none;
	box-shadow: none;
	border: 0;
	font-size: inherit;
}
a.inherit {
	color: inherit;
	text-decoration: none;
}

:root {
	--gap: 5vw;
	--row-gap: 0.55rem;
}
@media (min-width: 990px) {
	:root {
		--gap: 18px;
	}
	.mobile-only {
		display: none !important;
	}
}

@media (max-width: 990px) {
	.desktop-only {
		display: none !important;
	}
}
