//This file is automatically generated by the build process
//Edit styles.json then run `npm run build` to update
$color-brand-primary: #141923;
$color-brand-primary-dark: #0c111b;
$color-brand-primary-modal: rgba(20,25,35,0.98);
$color-brand-primary-overlay: rgba(20,25,35,0.7);
$color-brand-secondary: #ff6e40;
$color-brand-link: #0fb5a7;
$color-offblack: #333;
$color-black: #1e1e1e;
$color-white: #ffffff;
$color-offwhite: #fafafa;
$color-desaturated-white-border: rgba(255,255,255,0.2);
$color-desaturated-white-text: rgba(255,255,255,0.7);
$color-desaturated-white-background: rgba(255,255,255,0.8);
$color-desaturated-black-border: rgba(0,0,0,0.1);
$color-desaturated-black-text: rgba(0,0,0,0.4);
$color-desaturated-black-background: rgba(0,0,0,0.3);
$color-shadow: rgba(0,0,0,0.25);
$color-inner-shadow: rgba(0,0,0,0.05);
$font-brand: alternate-gothic-no-3-d, sans-serif;
$font-main: source-sans-pro, helvetica neue, helvetica, arial, sans-serif;
$font-highlight: roboto-condensed, helvetica neue, helvetica, arial, sans-serif;
$size-gap: var(--gap);
$size-gap-quarter: calc(var(--gap) / 4);
$size-gap-half: calc(var(--gap) / 2);
$size-gap-2x: calc(var(--gap) * 2);
$size-gap-3x: calc(var(--gap) * 3);
$size-gap-4x: calc(var(--gap) * 4);
$size-gap-6x: calc(var(--gap) * 6);
$size-row-gap: var(--row-gap);
$size-large-mobile-width: 640px;
$size-tablet-width: 768px;
$size-desktop-width: 990px;
$size-content-width: 1330px;
:root {
--color-brand-primary: #141923;
--color-brand-primary-dark: #0c111b;
--color-brand-primary-modal: rgba(20,25,35,0.98);
--color-brand-primary-overlay: rgba(20,25,35,0.7);
--color-brand-secondary: #ff6e40;
--color-brand-link: #0fb5a7;
--color-offblack: #333;
--color-black: #1e1e1e;
--color-white: #ffffff;
--color-offwhite: #fafafa;
--color-desaturated-white-border: rgba(255,255,255,0.2);
--color-desaturated-white-text: rgba(255,255,255,0.7);
--color-desaturated-white-background: rgba(255,255,255,0.8);
--color-desaturated-black-border: rgba(0,0,0,0.1);
--color-desaturated-black-text: rgba(0,0,0,0.4);
--color-desaturated-black-background: rgba(0,0,0,0.3);
--color-shadow: rgba(0,0,0,0.25);
--color-inner-shadow: rgba(0,0,0,0.05);
--font-brand: alternate-gothic-no-3-d, sans-serif;
--font-main: source-sans-pro, helvetica neue, helvetica, arial, sans-serif;
--font-highlight: roboto-condensed, helvetica neue, helvetica, arial, sans-serif;
--size-gap: var(--gap);
--size-gap-quarter: calc(var(--gap) / 4);
--size-gap-half: calc(var(--gap) / 2);
--size-gap-2x: calc(var(--gap) * 2);
--size-gap-3x: calc(var(--gap) * 3);
--size-gap-4x: calc(var(--gap) * 4);
--size-gap-6x: calc(var(--gap) * 6);
--size-row-gap: var(--row-gap);
--size-large-mobile-width: 640px;
--size-tablet-width: 768px;
--size-desktop-width: 990px;
--size-content-width: 1330px;
}
