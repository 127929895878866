@use '~/src/lib/mixins';
.Suspended {
	@include mixins.center-column;
	width: 100%;
}

.Error {
	@include mixins.center-column;
	flex-direction: row;
	gap: var(--size-gap-2x);
	font-family: var(--font-highlight);
}
